import { React, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { db, auth, GoogleProvider } from '../config/firebase';
import { collection, setDoc, getDoc, doc } from 'firebase/firestore';
import { signInWithEmailAndPassword, signInWithPopup, signInAnonymously } from 'firebase/auth';

import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopUp';

import '../styles/Login.css';

function Login() {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const usersCollectionRef = collection(db, "users");

  const searchParams = new URLSearchParams(window.location.search); 
  const redirect = searchParams.get('redirect');

  const handleError = (error) => {
    let message = 'An error occurred. Please try again.';
    if (error.code === 'auth/wrong-password') {
      message = 'Incorrect password. Please check and try again.';
    } else if (error.code === 'auth/user-not-found') {
      message = "User not found. Please verify your email or sign up.";
    } else if (error.code === 'auth/invalid-email') {
      message = "Invalid Email. Please verify your email.";
    } else if (error.code === 'auth/missing-password') {
      message = "Missing Password. Please verify your password.";
    } else if (error.code === 'auth/invalid-credential') {
      message = "Invalid Credentials. Please verify your credentials.";
    } else if (error.code === 'auth/popup-closed-by-user') {
      message = 'Login canceled. Please try again.';
    }
    setFeedbackMessage(message);
    setIsPopupOpen(true);
  };

  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if(redirect === null) {
        navigate('/');
      } else {
        navigate(redirect);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const loginWithGoogle = async () => {
    try {
      const cred = await signInWithPopup(auth, GoogleProvider);
      // Check if the user already exists in the database
      const userDoc = doc(usersCollectionRef, cred.user.uid);
      const docSnap = await getDoc(userDoc);
      if (!docSnap.exists()) {
        // If not, add the user to firestore db
        await setDoc(userDoc, {
          email: cred.user.email,
          registrationDate: new Date()
        });

        // POST user to MongoDB
        axios.post(process.env.REACT_APP_API_URL + '/user/create', {
          firebaseUserId: cred.user.uid,
          email: cred.user.email,
          name: cred.user.displayName.split(' ')[0],
          surname: cred.user.displayName.split(' ').slice(1).join(' ')
        }, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        });
      }
      if(redirect === null) {
        navigate('/');
      } else {
        navigate(redirect);
      }
    } catch (err) {
      handleError(err);
    }
  };

  // Function to handle anonymous login
  const loginAnonymously = async () => {
    try {
      await signInAnonymously(auth);

      const placeholderEmail = Date.now() + '-' + Math.random().toString(36).substring(2, 15) + '@placeHolderEmail.com';

      // Use the onAuthStateChanged listener to get the user ID
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          try {
            // POST user to MongoDB
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/create`,
              {
                firebaseUserId: user.uid,
                email: placeholderEmail,
                type: 'guest',
                name: null,
                surname: null
              },
              {
                headers: {
                  'X-API-KEY': process.env.REACT_APP_API_KEY
                }
              }
            );

            // Navigate only after the user is successfully created
            if (response.status === 200) {
              navigate('/');
            } else {
              console.error('User creation failed:', response.data);
            }
          } catch (postError) {
            console.error('Error posting user to MongoDB:', postError);
          }
        } else {
          console.error('User object is null');
        }
      });
    } catch (err) {
      console.error('Anonymous login error:', err);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage("");
  };

  return (
    <div>
      <Navbar />
      <div className='global-container'>
        <div className='login-container'>
          <h2 className='login-title'>Login</h2>
          <div className='login-form'>
            <input 
              type='email' 
              className='login-input' 
              placeholder='Email' 
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
            <input 
              type='password' 
              className='login-input' 
              placeholder='Password' 
              onChange={(e) => setPassword(e.target.value)}
              required 
            />
            <button type='submit' className='login-button' onClick={login}>Login</button>
          </div>
          <div className='login-link'>
            <p>Don't have an account? <Link to='/signup' style={{ color: 'red' }}>Sign up</Link></p>
          </div>
          <div className='or-divider'>
            <span>or</span>
          </div>
          <button className='google-button' onClick={loginWithGoogle}>Continue with Google</button>
          <button className='google-button' onClick={loginAnonymously}>Continue as Guest</button>
        </div>
      </div>
      <Footer />
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
      />
    </div>
  );
}

export default Login;
