import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import '../styles/About.css';

function About() {
  return (
    <div style={{ backgroundColor: '#ececec' }}>
      <Helmet>
        <title>About | Dal Fiorentino</title>
        <meta name="description" content="We are passionate about bringing the flavours of Florence to your table, with our authentic Schiacciate Fiorentine!" />        
        <meta name="twitter:card" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta name="twitter:site" content="@dalfiorentino.london" />        
        <meta name="twitter:creator" content="@dalfiorentino.london" />        
        <meta name="twitter:title" content="Dal Fiorentino | Italian Flatbreads" />        
        <meta name="twitter:description" content="We are passionate about bringing the flavours of Florence to your table, with our authentic Schiacciate Fiorentine!" />        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta property="og:title" content="Dal Fiorentino | Italian Flatbreads" />        
        <meta property="og:description" content="We are passionate about bringing the flavours of Florence to your table, with our authentic Schiacciate Fiorentine!" />        
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Dal Fiorentino | Italian Flatbreads" />
        <meta property="og:locale" content="en_UK" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Dal Fiorentino, Italian flatbreads, schiacciate, Tuscan bread, traditional Italian shop, authentic Italian food, schiacciata London, Italian bakery, Italian cuisine London, fresh flatbreads, Tuscany specialties" />
      </Helmet>
      <Navbar />
      <div className='menu-container'>
        <div className='about-container'>
          <br /><br />
          <h2 className='menu-title'>ABOUT US</h2>
          <p className='menu-welcome'>
            Welcome to our Italian shop in London, where tradition meets modern taste.
          </p>

          <br /><br />

          <div className='about-section'>
            <div className='about-text'>
              <p className='about-description'>
                We are passionate about bringing the flavours of Florence to your table, with our authentic Schiacciate Fiorentine.
                Made with the freshest ingredients and traditional recipes, our flatbread is a taste of Italy in every bite.
              </p>
            </div>
            <div className='about-image-container'>
              <img src={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} alt="Italian Shop" className="about-image"/>
            </div>
          </div>

          <div className='about-section reverse'>
            <div className='about-image-container'>
              <img src={`${process.env.REACT_APP_API_URL}/images/about-2.jpg`} alt="Schiacciate Fiorentine" className="about-image"/>
            </div>
            <div className='about-text'>
              <p className='about-description'>
                At Dal Fiorentino, we believe in the power of food to bring people together.
                That's why we strive to create an atmosphere that is warm, welcoming, and reflective of the Italian culture.
                Whether you're a local or a tourist, we want you to feel at home when you visit us.
              </p>
            </div>
          </div>

          <div className='about-section'>
            <div className='about-text'>
              <p className='about-description'>
                Our team is made up of passionate foodies and experts in Italian cuisine, who are dedicated to providing you with the best possible experience.
                From the moment you walk through our doors, we strive to make sure that you feel like a valued customer.
              </p>
            </div>
            <div className='about-image-container'>
              <img src={`${process.env.REACT_APP_API_URL}/images/about-3.jpg`} alt="Italian Atmosphere" className="about-image"/>
            </div>
          </div>

          <br />

          <div className="about-video">
            <iframe 
              src="https://www.instagram.com/reel/C5gaeGjoKkN/embed" 
              width="400" 
              height="540" 
              frameBorder="0" 
              scrolling="no" 
              allowTransparency="true" 
              allow="encrypted-media">
            </iframe>
          </div>

          <p className='about-ending'>We can't wait to welcome you to Dal Fiorentino and share our love for Schiacciate Fiorentine with you.</p>
          <p className='about-ending'><em>Buon appetito!</em></p>
          
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
