import { React, useState, useEffect } from 'react';

import axios from 'axios';

import { Helmet } from 'react-helmet-async';

import { useAuth } from '../components/AuthContext';

import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

function Account() {

  const { user } = useAuth();

  const [userData, setUserData] = useState();

  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if(userData) {
      console.log(userData)
      setLoadingOrders(true);
      const fetchProducts = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + '/orders/' + userData._id, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
          });
          setOrders(response.data.orders);
          setLoadingOrders(false);
        } catch (error) {
          console.error('Error fetching orders:', error);
          setLoadingOrders(false);
        }
      };
  
      fetchProducts(); 
    }
  }, [userData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <Helmet>
        <title>Account | Dal Fiorentino</title>
        <meta name="description" content="Manage your account at Dal Fiorentino. View your order history, update personal details, and track your favorite Italian products." />        
        <meta name="twitter:card" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta name="twitter:site" content="@dalfiorentino.london" />        
        <meta name="twitter:creator" content="@dalfiorentino.london" />        
        <meta name="twitter:title" content="Dal Fiorentino | Italian Flatbreads" />        
        <meta name="twitter:description" content="Manage your account at Dal Fiorentino. View your order history, update personal details, and track your favorite Italian products." />        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta property="og:title" content="Dal Fiorentino | Italian Flatbreads" />        
        <meta property="og:description" content="Manage your account at Dal Fiorentino. View your order history, update personal details, and track your favorite Italian products." />        
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Dal Fiorentino | Italian Flatbreads" />
        <meta property="og:locale" content="en_UK" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Dal Fiorentino, account management, order history, update details, track orders, Italian products account, personal account Dal Fiorentino, Italian shop London, manage orders, online account London Italian shop" />
      </Helmet>
      <Navbar />

      <div className='global-container'>
        {userData && (
          <h2>Welcome back {userData.name}!</h2>
        )}
        <br /><br />
        <h2>Your orders</h2>
        <br /><br />
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Items</th>
                <th>Pick-up Store</th>
                <th>Pick-up</th>
                <th>Order Date</th>
              </tr>
            </thead>
            <tbody>
              {orders.filter(order => order.status != 'pending').map(order => (
                <tr key={order._id}>
                  <td style={{ fontSize: '20px' }}><b>#{order.orderNumber}</b></td>
                  <td>
                    {order.basketItems.map(item => (
                      <>
                        <br />
                        <p key={item._id}><b>{item.quantity}</b> | {item.product.name} | {item.product.pricePerKgInCents === 0 ? 'Whole' : item.grams + 'g'}</p>
                        <br />
                      </>
                    ))}
                  </td>
                  <td><b>{order.store.name}</b></td>
                  <td><b>{formatDate(order.selectedDate)}</b></td>
                  <td>{formatDate(order.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p></p>
      </div>

      <Footer />
    </div>
  );
}

export default Account;
