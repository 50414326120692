import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useAuth } from '../../components/AuthContext';
import '../../styles/components/common/Footer.css';

function Footer() {
  const { user } = useAuth();

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    };
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
          <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
          <Link to="/contact" className="footer-link">Contact Us</Link>
          {user ? (
            <Link to="/" className="footer-link" onClick={logout}>{user.email ? user.email : 'Guest'} (Logout)</Link>
          ) : (
            <Link to="/login" className="footer-link" onClick={logout}>Login</Link>
          )}
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=100088926296043" className="social-link"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.tiktok.com/@dalfiorentino" className="social-link"><i className="fab fa-tiktok"></i></a>
          <a href="https://instagram.com/dalfiorentino.london" className="social-link"><i className="fab fa-instagram"></i></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
